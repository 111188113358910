import React from 'react';
import { Route, Routes as ReactRouterRoutes } from 'react-router-dom';
import LandingPage from '../LandingPage/landing';
import SignUp from '../SignUpPage/SignUpPage';
import SignIn from '../SignInPage/SignIn';
import ForgotPassword from '../ForgotPassword/forgotpassword';
import SuccessSignUp from '../SignUpPage/successsignup';
import EmailVerified from '../Email/emailverified';
import PasswordResetEmailSuccess from '../Email/PasswordResetEmailSuccess';
import PasswordReset from '../ForgotPassword/PasswordReset';
import ResetSuccess from '../Email/ResetSuccess';
import Home from '../Home/home';
import CreateGym from '../Gym/CreateGym';
import Members from '../Members/members';
import MemberPassSetup from '../Members/memberPassSetup';
import MemberAccountSuccess from '../Members/memberAccountSucces';
import ProtectedRoute from './ProtectedRoute';
import Packages from '../Packages/package';
import PackageDetails from '../Packages/package_detail';
import MemberDetail from '../Members/member-detail';
import GymDashboard from '../Dashboard /GymDashboard';
import Classes from '../Class/class';
import ClassDetails from '../Class/classDetails';
import NotFound from '../Notfound/NotFound';
import Workouts from '../Workout/workoutPage';
import WorkoutDetails from '../Workout/WorkoutDetail';
import WorkoutExerciseDetails from '../Workout/WorkoutExerciseDetail';
import WorkoutTemplates from '../WorkoutTemplate/WorkoutTemplate';
import WorkoutTemplateDetail from '../WorkoutTemplate/workoutTemplateDetail';
import Billings from '../Billings/Billings';  // Import the Billings component

const Routes = ({ isAuthenticated, setIsAuthenticated }) => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/get-started" element={<SignUp />} />
      <Route path="/signup-success" element={<SuccessSignUp />} />
      <Route path="/auth/verify-email/:key" element={<EmailVerified />} />
      <Route path="/password-reset-email-success" element={<PasswordResetEmailSuccess />} />
      <Route path="/auth/reset-password/:unique_string" element={<PasswordReset />} />
      <Route path="/reset-success" element={<ResetSuccess />} />
      <Route path="/auth/invite-email/action/:unique_string" element={<MemberPassSetup />} />
      <Route path="/auth/successful-account-setup/:unique_string" element={<MemberAccountSuccess />} />
      <Route path="*" element={<NotFound />} />
      <Route
        path="/home"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-gym"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <CreateGym />
          </ProtectedRoute>
        }
      />
      <Route
        path="/members"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Members />
          </ProtectedRoute>
        }
      />
      <Route
        path="/packages"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Packages />
          </ProtectedRoute>
        }
      />
      <Route
        path="/packages/:id"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <PackageDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/member/:gymId/:memberId"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <MemberDetail />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <GymDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/classes"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Classes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/classes/:id"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <ClassDetails />
          </ProtectedRoute>
        }
      />
      {/* Workout routes */}
      <Route
        path="/workouts"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Workouts />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workouts/:id"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <WorkoutDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workouts/exercise/:id"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <WorkoutExerciseDetails />
          </ProtectedRoute>
        }
      />
      {/* New Workout Template routes */}
      <Route
        path="/workout-templates"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <WorkoutTemplates />
          </ProtectedRoute>
        }
      />
      <Route
        path="/workout-templates/:id"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <WorkoutTemplateDetail />
          </ProtectedRoute>
        }
      />
      {/* Add Billings route */}
      <Route
        path="/billings"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Billings />
          </ProtectedRoute>
        }
      />
    </ReactRouterRoutes>
  );
};

export default Routes;