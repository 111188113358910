import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MapComponent from '../Map/map';

const CreateGym = ({ onClose }) => {
  const [gymName, setGymName] = useState('');
  const [gymDescription, setGymDescription] = useState('');
  const [gymPhoneNumber, setGymPhoneNumber] = useState('');
  const [gymLandmark, setGymLandmark] = useState('');
  const [gymProfilePicture, setGymProfilePicture] = useState(null);
  const [gymLocation, setGymLocation] = useState({ latitude: '', longitude: '' });
  const [paymentDetails, setPaymentDetails] = useState(''); 
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('gym_name', gymName);
    formData.append('gym_description', gymDescription);
    formData.append('gym_phone_number', gymPhoneNumber);
    formData.append('gym_landmark', gymLandmark);
    formData.append('profile_image', gymProfilePicture);
    formData.append('gym_latitude', gymLocation.latitude);
    formData.append('gym_longitude', gymLocation.longitude);
    formData.append('payment_details', paymentDetails);
  
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}gyms/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token ${token}`,
        },
      });
      navigate('/home'); // Redirect on successful gym creation
    } catch (err) {
      console.error('Error creating gym:', err.response ? err.response.data : err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setGymName('');
    setGymDescription('');
    setGymPhoneNumber('');
    setGymLandmark('');
    setGymProfilePicture(null);
    setGymLocation({ latitude: '', longitude: '' });
    setPaymentDetails('');
    if (onClose) onClose(); // Close the modal if onClose is provided
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg w-full">
        <div className="flex justify-between items-center p-4 border-b">
          <h3 className="text-lg leading-6 font-bold text-gray-900">Create Gym</h3>
          <button onClick={handleCancel} className="text-gray-500 hover:text-gray-700">
            &times;
          </button>
        </div>
        
        {/* Motivational text */}
        <p className="text-center text-gray-600 mt-2 mb-6 px-4">
          <em>Your journey to <strong>efficient gym management</strong> begins here. Let’s get your gym set up!</em>
        </p>

        <form onSubmit={handleSubmit} className="p-6 overflow-y-auto max-h-[80vh]">
          <div className="mb-4">
            <label htmlFor="gym-name" className="block text-sm font-bold text-gray-700">Gym Name</label>
            <input
              type="text"
              id="gym-name"
              className="mt-1 block w-full p-2 border border-black rounded"
              placeholder="Enter gym name"
              value={gymName}
              onChange={(e) => setGymName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-description" className="block text-sm font-bold text-gray-700">Gym Description</label>
            <textarea
              id="gym-description"
              className="mt-1 block w-full p-2 border border-black rounded"
              placeholder="Enter the gym's mission or motto"
              rows="3"
              value={gymDescription}
              onChange={(e) => setGymDescription(e.target.value)}
              required
            ></textarea>
            <p className="mt-1 text-sm text-gray-500">E.g., We are committed to ensuring you meet your fitness and health goals.</p>
          </div>
          <div className="mb-4">
            <label htmlFor="gym-phone-number" className="block text-sm font-bold text-gray-700">Gym Phone Number</label>
            <input
              type="tel"
              id="gym-phone-number"
              className="mt-1 block w-full p-2 border border-black rounded"
              placeholder="Enter gym phone number"
              value={gymPhoneNumber}
              onChange={(e) => setGymPhoneNumber(e.target.value.replace(/\D/, ''))} // restrict to numbers only
              required
            />
            <p className="mt-1 text-sm text-gray-500">Enter a valid phone number, e.g., 0712345678 or 0112345678.</p>
          </div>
          <div className="mb-4">
            <label htmlFor="gym-landmark" className="block text-sm font-bold text-gray-700">Gym Landmark</label>
            <input
              type="text"
              id="gym-landmark"
              className="mt-1 block w-full p-2 border border-black rounded"
              placeholder="Near Westlands Stage opposite Westlands Mall"
              value={gymLandmark}
              onChange={(e) => setGymLandmark(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="payment-details" className="block text-sm font-bold text-gray-700">Gym Member Payment Details</label>
            <input
              type="text"
              id="payment-details"
              className="mt-1 block w-full p-2 border border-black rounded"
              placeholder="e.g., Paybill 247247 account number 90455 or Till 90455"
              value={paymentDetails}
              onChange={(e) => setPaymentDetails(e.target.value)}
            />
            <p className="mt-1 text-sm text-gray-500">Include payment instructions like Paybill, bank details, etc.</p>
          </div>
          <div className="mb-4">
            <label htmlFor="gym-profile-picture" className="block text-sm font-bold text-gray-700">Gym Logo</label>
            <input
              type="file"
              id="gym-profile-picture"
              className="mt-1 block w-full text-sm text-gray-500 border border-black rounded"
              onChange={(e) => setGymProfilePicture(e.target.files[0])}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="gym-map" className="block text-sm font-bold text-gray-700">Gym Location</label>
            <p className="mt-1 text-sm text-gray-500">
              Use the map to find your gym's location. Zoom in, adjust the view, and click to place a pin at your exact location to help existing and new members find you easily.
            </p>
            <MapComponent setGymLocation={setGymLocation} />
          </div>
          <div className="flex items-center justify-end mt-6 space-x-3">
            <button
              type="button"
              onClick={handleCancel}
              className="px-4 py-2 border border-gray-300 rounded text-gray-700 hover:bg-gray-100 focus:outline-none"
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 border border-transparent text-white rounded bg-blue-600 hover:bg-blue-700 focus:outline-none ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
            >
              {loading ? 'Creating...' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGym;