import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const PasswordReset = () => {
  const { unique_string } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const validatePassword = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return password.length >= minLength && specialCharRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    if (!validatePassword(newPassword)) {
      setMessage('Password must be at least 8 characters long and contain at least one special character');
      return;
    }

    try {
      const response = await axios.post(`${API_URL}users/auth/reset-password/${unique_string}/`, { new_password: newPassword, confirm_password: confirmPassword });
      setMessage(response.data.message);
      if (response.status === 200) {
        navigate('/reset-success');
      }
    } catch (error) {
      setMessage(error.response.data.error || 'An error occurred while resetting the password');
      console.error(error);
    }
  };

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Reset Password</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="new-password" className="block text-gray-700 mb-2">New Password</label>
            <input
              type="password"
              id="new-password"
              className="w-full p-2 border rounded"
              placeholder="Enter your new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="confirm-password" className="block text-gray-700 mb-2">Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              className="w-full p-2 border rounded"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full bg-black text-white p-2 rounded hover:bg-gray-800">
            Submit
          </button>
        </form>
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}
      </div>
    </div>
  );
};

export default PasswordReset;