import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useParams } from 'react-router-dom';  

const PackageDetails = () => {
  const [packageData, setPackageData] = useState(null);
  const [members, setMembers] = useState([]); 
  const [memberCount, setMemberCount] = useState(0); 
  const [projectedRevenue, setProjectedRevenue] = useState(0); 
  const { id } = useParams();  
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}packages/${id}/members/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setPackageData(response.data.package);
        setMembers(response.data.members || []);  
        setMemberCount(response.data.member_count); 
        setProjectedRevenue(response.data.projected_revenue_per_month); 
      } catch (err) {
        console.error('Error fetching package details:', err);
      }
    };

    fetchPackageDetails();
  }, [API_URL, id]);

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        {packageData ? (
          <div>
            <div className="mb-6">
              <h1 className="text-2xl font-bold">Package Details</h1>
              <div className="bg-white p-4 rounded-lg shadow mt-4">
                <h2 className="font-bold text-xl">{packageData.package_name}</h2>
                <p className="text-gray-600">{packageData.package_description || 'No description available'}</p>
                <p className="font-bold mt-2">Ksh {packageData.amount}</p>
                <p className="text-gray-500">Duration: {packageData.duration} month(s)</p>
                <p className="mt-2"><strong>Members Count:</strong> {memberCount}</p> {/* Display member count */}
                <p className="mt-2"><strong>Projected Monthly Revenue:</strong> Ksh {projectedRevenue}</p> {/* Display projected revenue */}
              </div>
            </div>
            <div>
              <h2 className="text-xl font-bold mb-4">Members Under This Package</h2>
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="py-2 px-4 border-b">Member Name</th>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">Phone Number</th>
                    <th className="py-2 px-4 border-b">Gender</th>
                    <th className="py-2 px-4 border-b">DOB</th>
                  </tr>
                </thead>
                <tbody>
                  {members.length > 0 ? (
                    members.map((member) => (
                      <tr key={member.id}>
                        <td className="py-2 px-4 border-b">{member.first_name} {member.last_name}</td>
                        <td className="py-2 px-4 border-b">{member.email}</td>
                        <td className="py-2 px-4 border-b">{member.phone_number}</td>
                        <td className="py-2 px-4 border-b">{member.gender}</td>
                        <td className="py-2 px-4 border-b">{new Date(member.DOB).toLocaleDateString()}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="py-2 px-4 border-b text-center">No members found for this package.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <p>Loading package details...</p>
        )}
      </main>
    </div>
  );
};

export default PackageDetails;