import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/sidebar";

const Billings = () => {
  const [activeTab, setActiveTab] = useState("transactions"); // Tabs: "billings" or "transactions"
  const [billings, setBillings] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("billing"); // "billing" or "transaction"
  const [memberSearch, setMemberSearch] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [billingDate, setBillingDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("Not Paid");
  const [amountPaid, setAmountPaid] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [currentBillingPage, setCurrentBillingPage] = useState(1);
  const [currentTransactionPage, setCurrentTransactionPage] = useState(1);
  const itemsPerPage = 10; // Items per page for pagination
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (activeTab === "billings") {
      fetchBillings();
    } else if (activeTab === "transactions") {
      fetchTransactions();
    }
  }, [activeTab]);

  const fetchBillings = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}billings/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      const updatedBillings = response.data.map((billing) => ({
        ...billing,
        payment_status:
          billing.balance === 0
            ? "Paid"
            : billing.payment_status === "Not Paid" &&
              new Date(billing.billing_date) < new Date()
            ? "Overdue"
            : billing.payment_status,
      }));
      setBillings(updatedBillings);
    } catch (err) {
      console.error("Error fetching billings:", err);
    }
  };

  const fetchTransactions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}transactions/`, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      setTransactions(response.data);
    } catch (err) {
      console.error("Error fetching transactions:", err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
      year: "numeric",
    });
  };

  const toggleModal = (type) => {
    setShowModal(!showModal);
    setModalType(type);
    setSelectedMember(null);
    setSelectedPackage(null);
    setMemberSearch("");
    setBillingDate("");
    setPaymentDate("");
    setAmountPaid("");
    setErrorMessage("");
  };

  const handleMemberSearch = async (e) => {
    setMemberSearch(e.target.value);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${API_URL}users/search-members/?query=${e.target.value}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setMemberResults(response.data);
    } catch (err) {
      console.error("Error searching members:", err);
    }
  };

  const handleSelectMember = (member) => {
    setSelectedMember(member);
    setMemberSearch(`${member.first_name} ${member.last_name}`);
    setMemberResults([]);

    if (member.package) {
      setSelectedPackage(member.package);
      setErrorMessage("");
    } else {
      setSelectedPackage(null);
      setErrorMessage("Selected member does not have an assigned package.");
    }
  };

  const handleCreateBilling = async () => {
    if (!selectedMember || !selectedPackage || !billingDate) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const gymId = selectedMember.gym;
      const data = {
        gym_member_id: selectedMember.id,
        gym_id: gymId,
        billing_date: billingDate,
        payment_status: paymentStatus,
        amount: selectedPackage.amount,
      };

      await axios.post(`${API_URL}billings/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      fetchBillings(); // Refresh billings list after creation
      toggleModal();
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  const handleCreateTransaction = async () => {
    if (!selectedMember || !paymentDate || !amountPaid) {
      setErrorMessage("Please fill out all required fields.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const data = {
        gym_member_id: selectedMember.id,
        payment_date: paymentDate,
        amount_paid: amountPaid,
      };

      await axios.post(`${API_URL}transactions/create/`, data, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });

      fetchBillings(); // Refresh billings to update payment status
      fetchTransactions(); // Refresh transactions after creation
      toggleModal();
    } catch (err) {
      if (err.response && err.response.status === 400 && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    }
  };

  const sendReminder = (id) => {
    const updatedBillings = billings.map((billing) => {
      if (billing.id === id) {
        return { ...billing, reminder_sent: true };
      }
      return billing;
    });
    setBillings(updatedBillings);
  };

  // Pagination logic
  const getCurrentItems = (items, currentPage) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedBillings = getCurrentItems(billings, currentBillingPage);
  const paginatedTransactions = getCurrentItems(transactions, currentTransactionPage);

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Billings Overview</h1>
          <button
            onClick={() => toggleModal(activeTab === "billings" ? "billing" : "transaction")}
            className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-all duration-300"
          >
            {activeTab === "billings" ? "Create Billing" : "Create Transaction"}
          </button>
        </div>

        <div className="mb-4 flex justify-between">
          <div className="flex space-x-4">
            <button
              className={`${
                activeTab === "billings"
                  ? "bg-blue-500 text-white font-bold"
                  : "bg-gray-200 text-gray-600"
              } py-2 px-4 rounded-lg hover:bg-blue-400`}
              onClick={() => setActiveTab("billings")}
            >
              Billings
            </button>
            <button
              className={`${
                activeTab === "transactions"
                  ? "bg-blue-500 text-white font-bold"
                  : "bg-gray-200 text-gray-600"
              } py-2 px-4 rounded-lg hover:bg-blue-400`}
              onClick={() => setActiveTab("transactions")}
            >
              Transactions
            </button>
          </div>
        </div>

        {activeTab === "billings" && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-lg rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Member Name</th>
                  <th className="py-3 px-6 text-left">Package Name</th>
                  <th className="py-3 px-6 text-left">Billing Date</th>
                  <th className="py-3 px-6 text-left">Amount</th>
                  <th className="py-3 px-6 text-left">Payment Status</th>
                  <th className="py-3 px-6 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {paginatedBillings.map((billing, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6">{billing.member_name}</td>
                    <td className="py-3 px-6">{billing.package_name}</td>
                    <td className="py-3 px-6">{formatDate(billing.billing_date)}</td>
                    <td className="py-3 px-6">Ksh {billing.amount_due}</td> 
                    <td className="py-3 px-6">
                      <span
                        className={`py-1 px-3 rounded-full text-xs font-semibold ${
                          billing.payment_status === "Paid"
                            ? "bg-green-200 text-green-600"
                            : billing.payment_status === "Partial"
                            ? "bg-orange-200 text-orange-600"
                            : "bg-red-200 text-red-600"
                        }`}
                      >
                        {billing.payment_status}
                      </span>
                    </td>
                    <td className="py-3 px-6">
                      {["Partial", "Overdue"].includes(billing.payment_status) &&
                        !billing.reminder_sent && (
                          <button
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300"
                            onClick={() => sendReminder(billing.id)}
                          >
                            Remind
                          </button>
                        )}
                      {billing.reminder_sent && (
                        <button
                          disabled
                          className="bg-gray-300 text-gray-600 px-4 py-2 rounded cursor-not-allowed"
                        >
                          Reminder Sent
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center mt-4">
              <button
                className={`px-3 py-1 border rounded-md ${
                  currentBillingPage > 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700 cursor-not-allowed"
                }`}
                disabled={currentBillingPage === 1}
                onClick={() => setCurrentBillingPage((prev) => Math.max(prev - 1, 1))}
              >
                Previous
              </button>
              <span className="mx-3 text-sm">
                Page {currentBillingPage} of{" "}
                {Math.ceil(billings.length / itemsPerPage)}
              </span>
              <button
                className={`px-3 py-1 border rounded-md ${
                  currentBillingPage <
                  Math.ceil(billings.length / itemsPerPage)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700 cursor-not-allowed"
                }`}
                disabled={
                  currentBillingPage ===
                  Math.ceil(billings.length / itemsPerPage)
                }
                onClick={() =>
                  setCurrentBillingPage((prev) =>
                    Math.min(prev + 1, Math.ceil(billings.length / itemsPerPage))
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
        )}

        {activeTab === "transactions" && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white shadow-lg rounded-lg">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">Member Name</th>
                  <th className="py-3 px-6 text-left">Amount Paid</th>
                  <th className="py-3 px-6 text-left">Balance</th>
                  <th className="py-3 px-6 text-left">Payment Date</th>
                  <th className="py-3 px-6 text-left">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {paginatedTransactions.map((transaction, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6">{transaction.member_name}</td>
                    <td className="py-3 px-6">Ksh {transaction.amount_paid}</td>
                    <td className="py-3 px-6">Ksh {transaction.balance}</td>
                    <td className="py-3 px-6">{formatDate(transaction.payment_date)}</td>
                    <td className="py-3 px-6">
                      {transaction.balance > 0 && (
                        <button
                          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300"
                          onClick={() => sendReminder(transaction.id)}
                        >
                          Remind
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-center mt-4">
              <button
                className={`px-3 py-1 border rounded-md ${
                  currentTransactionPage > 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700 cursor-not-allowed"
                }`}
                disabled={currentTransactionPage === 1}
                onClick={() =>
                  setCurrentTransactionPage((prev) =>
                    Math.max(prev - 1, 1)
                  )
                }
              >
                Previous
              </button>
              <span className="mx-3 text-sm">
                Page {currentTransactionPage} of{" "}
                {Math.ceil(transactions.length / itemsPerPage)}
              </span>
              <button
                className={`px-3 py-1 border rounded-md ${
                  currentTransactionPage <
                  Math.ceil(transactions.length / itemsPerPage)
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700 cursor-not-allowed"
                }`}
                disabled={
                  currentTransactionPage ===
                  Math.ceil(transactions.length / itemsPerPage)
                }
                onClick={() =>
                  setCurrentTransactionPage((prev) =>
                    Math.min(
                      prev + 1,
                      Math.ceil(transactions.length / itemsPerPage)
                    )
                  )
                }
              >
                Next
              </button>
            </div>
          </div>
        )}

        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">
                {modalType === "billing" ? "Create Billing" : "Create Transaction"}
              </h2>

              <div>
                <label className="block text-gray-700 mb-2">Member</label>
                <input
                  type="text"
                  value={memberSearch}
                  onChange={handleMemberSearch}
                  placeholder="Search for member..."
                  className="w-full border border-gray-300 p-2 rounded mb-2"
                />
                <div className="bg-white shadow-md max-h-32 overflow-y-auto">
                  {memberResults.map((member) => (
                    <div
                      key={member.id}
                      onClick={() => handleSelectMember(member)}
                      className="cursor-pointer p-2 hover:bg-gray-200"
                    >
                      {member.first_name} {member.last_name}
                    </div>
                  ))}
                </div>
              </div>

              {modalType === "billing" && (
                <>
                  <div className="mt-4">
                    <label className="block text-gray-700 mb-2">Billing Date</label>
                    <input
                      type="date"
                      value={billingDate}
                      onChange={(e) => setBillingDate(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded mb-2"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-gray-700 mb-2">Payment Status</label>
                    <select
                      value={paymentStatus}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded"
                    >
                      <option value="Not Paid">Not Paid</option>
                      <option value="Paid">Paid</option>
                      <option value="Partial">Partial</option>
                    </select>
                  </div>
                </>
              )}

              {modalType === "transaction" && (
                <>
                  <div className="mt-4">
                    <label className="block text-gray-700 mb-2">Monthly Amount Expected</label>
                    <input
                      type="text"
                      value={
                        selectedPackage
                          ? `Ksh ${selectedPackage.amount}`
                          : "N/A"
                      }
                      readOnly
                      className="w-full border border-gray-300 p-2 rounded mb-2"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-gray-700 mb-2">Payment Date</label>
                    <input
                      type="date"
                      value={paymentDate}
                      onChange={(e) => setPaymentDate(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded mb-2"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-gray-700 mb-2">Amount Paid</label>
                    <input
                      type="number"
                      value={amountPaid}
                      onChange={(e) => setAmountPaid(e.target.value)}
                      className="w-full border border-gray-300 p-2 rounded mb-2"
                    />
                  </div>
                </>
              )}

              {errorMessage && (
                <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
              )}

              <div className="flex justify-end mt-4">
                <button
                  onClick={() => toggleModal(null)}
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                >
                  Cancel
                </button>
                <button
                  onClick={
                    modalType === "billing" ? handleCreateBilling : handleCreateTransaction
                  }
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Billings;