import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';  // Import useNavigate for navigation
import Sidebar from '../Sidebar/sidebar';

const WorkoutDetails = () => {
  const { id } = useParams();  
  const [workout, setWorkout] = useState(null);  
  const [exercises, setExercises] = useState([]);  
  const [newExercise, setNewExercise] = useState({
    exercise_name: '',
    description: '',  // Keeping the description field
    muscle_group: '', // Added muscle_group field for exercise
    profile_photo_name: null,
  });
  const [showModal, setShowModal] = useState(false);  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL; 
  const navigate = useNavigate();  // Use navigate to redirect to the exercise details page

  useEffect(() => {
    fetchWorkoutDetails();
    fetchExercises();
  }, [id]);

  // Fetch workout details
  const fetchWorkoutDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setWorkout(response.data);
    } catch (err) {
      console.error('Error fetching workout details:', err);
      setError('Failed to fetch workout details. Please try again later.');
    }
  };

  // Fetch exercises for this workout
  const fetchExercises = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/${id}/exercises/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setExercises(response.data);
    } catch (err) {
      console.error('Error fetching exercises:', err);
      setError('Failed to fetch exercises. Please try again later.');
    }
  };

  // Handle input changes for new exercise
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewExercise((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
  };

  // Handle file change for new exercise image
  const handleFileChange = (e) => {
    setNewExercise((prevExercise) => ({
      ...prevExercise,
      profile_photo_name: e.target.files[0],
    }));
  };

  // Add new exercise
  const handleAddExercise = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    setError(null);

    const formData = new FormData();
    formData.append('exercise_name', newExercise.exercise_name);
    formData.append('description', newExercise.description); // Keeping description
    formData.append('muscle_group', newExercise.muscle_group);  // Added muscle group
    if (newExercise.profile_photo_name) {
      formData.append('profile_photo_name', newExercise.profile_photo_name);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}workouts/${id}/exercises/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowModal(false);
      fetchExercises(); 
      
      setNewExercise({
        exercise_name: '',
        description: '',  // Reset description
        muscle_group: '',  // Reset muscle_group
        profile_photo_name: null,
      });
    } catch (err) {
      console.error('Error adding exercise:', err);
      setError('Failed to add exercise. Please check your inputs and try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle navigation to the exercise details page
  const handleExerciseView = (exerciseId) => {
    navigate(`/workouts/exercise/${exerciseId}`);  // Redirect to the exercise details page using the exercise ID
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        {workout && (
          <>
            <h2 className="text-2xl font-bold mb-4">Workout Details</h2>
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
              <div className="flex items-center mb-4">
                <img 
                  src={workout.profile_photo_path } 
                  alt="Workout" 
                  className="w-24 h-24 rounded mr-4"
                />
                <div>
                  <h3 className="text-xl font-semibold">{workout.name}</h3>
                  <p className="text-gray-600">Muscle Group: {workout.muscle_group}</p>
                  <p className="text-gray-600">Created By: {workout.created_by_name}</p>
                  <p className="text-gray-600">Description: {workout.description}</p> {/* Description maintained */}
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Exercises List</h3>
              <button 
                className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-all duration-300 ease-in-out"
                onClick={() => setShowModal(true)}
              >
                Add Exercise
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow-lg rounded-lg">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Exercise Image</th>
                    <th className="py-3 px-6 text-left">Exercise Name</th>
                    <th className="py-3 px-6 text-left">Muscle Group</th> {/* Changed from Description to Muscle Group */}
                    <th className="py-3 px-6 text-left">Created By</th>
                    <th class="py-3 px-6 text-left">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {exercises.map((exercise) => (
                    <tr key={exercise.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6">
                        <img 
                          src={exercise.profile_photo_path || "https://www.tailwindai.dev/placeholder.svg"} 
                          alt="Exercise" 
                          className="w-16 h-16 rounded"
                        />
                      </td>
                      <td className="py-3 px-6">{exercise.exercise_name}</td>
                      <td className="py-3 px-6">{exercise.muscle_group}</td>  
                      <td className="py-3 px-6">{exercise.created_by_name}</td>
                      <td class="py-3 px-6">
                        <button 
                          class="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-400 transition-all duration-300 ease-in-out"
                          onClick={() => handleExerciseView(exercise.id)} 
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Exercise</h3>
                <form onSubmit={handleAddExercise}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Exercise Name</label>
                    <input
                      type="text"
                      name="exercise_name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newExercise.exercise_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Muscle Group</label> {/* Added muscle group input */}
                    <input
                      type="text"
                      name="muscle_group"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newExercise.muscle_group}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Description</label> {/* Description retained */}
                    <textarea
                      name="description"
                      rows="3"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newExercise.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
                    <input
                      type="file"
                      name="profile_photo_name"
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      onChange={handleFileChange}
                    />
                  </div>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <div className="mt-6 flex items-center justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Adding...' : 'Add Exercise'}
                    </button>
                  </div> 
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default WorkoutDetails;

