import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../Routes/AuthContext';  

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();  // Get login function from AuthContext
  const API_URL = process.env.REACT_APP_API_URL;

  // Redirect to home or create-gym if already authenticated
  useEffect(() => {
    const token = localStorage.getItem('token');
    const has_gym = localStorage.getItem('has_gym');

    if (token) {
      if (has_gym === 'true') {
        navigate('/home');
      } else {
        navigate('/create-gym');
      }
    }
  }, [navigate]);

  // Handle form submission and login
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_URL}users/auth/login/`, { email, password });

      // Save token and login globally through AuthContext
      login(response.data.token);  // Update global authentication state

      // Still store user info locally for use in the app
      localStorage.setItem('user', JSON.stringify(response.data.user));
      localStorage.setItem('profile', JSON.stringify(response.data.profile));
      localStorage.setItem('has_gym', response.data.has_gym);

      setError('');

      // Redirect based on gym ownership status
      if (response.data.has_gym) {
        navigate('/home');
      } else {
        navigate('/create-gym');
      }

    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || 'Invalid credentials');
      } else {
        setError('An error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="flex flex-col md:flex-row w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="w-full md:w-1/2 bg-black p-8 flex flex-col justify-center">
          <div className="text-white text-4xl font-bold mb-4">Welcome</div>
          <div className="text-white text-2xl font-bold mb-8">GymFit</div>
          <img src="https://placehold.co/300x300" alt="Illustration" className="w-full" />
        </div>
        <div className="w-full md:w-1/2 p-8">
          <h2 className="text-2xl font-bold mb-6 text-center">Sign In</h2>
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border rounded"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
              <input
                type="password"
                id="password"
                className="w-full p-2 border rounded"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center justify-between mb-4">
              <Link to="/forgot-password" className="text-blue-500 hover:underline">Forgot Password?</Link>
            </div>
            <button type="submit" className="w-full bg-black text-white p-2 rounded hover:bg-gray-800">Login</button>
          </form>
          <p className="mt-6 text-center text-gray-700">
            Don't have an account? <Link to="/register" className="text-blue-500 hover:underline">Sign up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;