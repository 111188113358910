import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleCancel = () => {
    navigate('/login');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}users/auth/password-reset-request/`, { email });
      navigate('/password-reset-email-success');
    } catch (err) {
      setError(err.response.data.error || 'An error occurred. Please try again.');
    }
  };

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Forgot Password</h1>
        <p className="text-gray-700 mb-4 text-center">Enter your email to reset your password</p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2 font-bold">Email</label>
            <input
              type="email"
              id="email"
              className="w-full p-2 border rounded"
              placeholder="Enter your email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <div className="flex items-center justify-between">
            <button type="submit" className="bg-black text-white p-2 rounded hover:bg-gray-800 w-full mr-2">Submit</button>
            <button type="button" onClick={handleCancel} className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400 w-full ml-2">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;