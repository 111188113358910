import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';  // Add useLocation to track route changes
import axios from 'axios';
import { useAuth } from './AuthContext';  // Ensure we are using AuthContext

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, logout } = useAuth();  // Get isAuthenticated and logout from AuthContext
  const location = useLocation();  // Track location (route changes)
  
  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log("No token found, logging out.");
        logout();  // Log out the user immediately
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}users/verify-token/`,
          { token: token },
          {
            headers: {
              Authorization: `Token ${token}`
            }
          }
        );

        if (!response.data.valid) {
          console.log("Token is invalid, logging out.");
          localStorage.removeItem('token');
          logout();
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        localStorage.removeItem('token');
        logout();
      } finally {
        setIsLoading(false);
      }
    };

    verifyToken();
  }, [location, logout]);  // Run this effect whenever the route (location) changes

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;