import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';

const WorkoutTemplates = () => {
  const [workoutTemplates, setWorkoutTemplates] = useState([]);
  const [newWorkoutTemplate, setNewWorkoutTemplate] = useState({
    name: '',
    description: '',
    exercises: [],
  });
  const [allExercises, setAllExercises] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkoutTemplates();
    fetchAllExercises();
  }, []);

  const fetchWorkoutTemplates = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/templates/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setWorkoutTemplates(response.data);
    } catch (err) {
      console.error('Error fetching workout templates:', err);
      setError('Failed to fetch workout templates. Please try again later.');
    }
  };

  const fetchAllExercises = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/1/exercises/`, { 
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setAllExercises(response.data);
      setFilteredExercises(response.data); 
    } catch (err) {
      console.error('Error fetching exercises:', err);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    if (term) {
      const filtered = allExercises.filter((exercise) =>
        exercise.exercise_name && exercise.exercise_name.toLowerCase().includes(term)
      );
      setFilteredExercises(filtered);
    } else {
      setFilteredExercises(allExercises); 
    }
  };

  const handleExerciseSelect = (exercise) => {
    if (!newWorkoutTemplate.exercises.includes(exercise.id)) {
      setNewWorkoutTemplate((prevTemplate) => ({
        ...prevTemplate,
        exercises: [...prevTemplate.exercises, exercise.id],
      }));
    }
  };

  const handleExerciseRemove = (exerciseId) => {
    setNewWorkoutTemplate((prevTemplate) => ({
      ...prevTemplate,
      exercises: prevTemplate.exercises.filter((id) => id !== exerciseId),
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkoutTemplate((prevTemplate) => ({
      ...prevTemplate,
      [name]: value,
    }));
  };

  const handleAddWorkoutTemplate = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}workouts/templates/`,
        newWorkoutTemplate,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setShowModal(false);
      fetchWorkoutTemplates(); 
      setNewWorkoutTemplate({
        name: '',
        description: '',
        exercises: [],
      });
    } catch (err) {
      console.error('Error adding new workout template:', err);
      if (err.response) {
        setError(`Failed to add workout template: ${err.response.data.message || 'Please check your inputs and try again.'}`);
      } else {
        setError('Failed to add workout template. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Workout Templates</h1>
          <button
            onClick={() => setShowModal(true)}
            className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-all duration-300 ease-in-out"
          >
            Add Workout Template
          </button>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Template Name</th>
                <th className="py-3 px-6 text-left">Exercise Count</th>
                <th className="py-3 px-6 text-left">Member Count</th> {/* Added Member Count column */}
                <th className="py-3 px-6 text-left">Created By</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {workoutTemplates.map((template) => (
                <tr key={template.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6">{template.name}</td>
                  <td className="py-3 px-6">{template.exercise_count}</td>
                  <td className="py-3 px-6">{template.member_count || 0}</td> {/* Display Member Count */}
                  <td className="py-3 px-6">{template.created_by_name || 'Unknown'}</td>
                  <td className="py-3 px-6">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                      onClick={() => navigate(`/workout-templates/${template.id}`)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal for Adding Workout Template */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Workout Template</h3>
                <form onSubmit={handleAddWorkoutTemplate}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Workout Template Name</label>
                    <input
                      type="text"
                      name="name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      placeholder="Enter template name"
                      value={newWorkoutTemplate.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Workout Template Description</label>
                    <textarea
                      name="description"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      placeholder="Enter description"
                      rows="3"
                      value={newWorkoutTemplate.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Search Exercise</label>
                    <input
                      type="text"
                      name="searchExercise"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      placeholder="Search for an exercise"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div className="mt-2 max-h-40 overflow-y-auto">
                      {filteredExercises.map((exercise) => (
                        <div
                          key={exercise.id}
                          className="cursor-pointer bg-gray-200 p-2 mb-1 rounded hover:bg-gray-300"
                          onClick={() => handleExerciseSelect(exercise)}
                        >
                          {exercise.exercise_name}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4 flex flex-wrap gap-2" id="selected-exercises">
                    {newWorkoutTemplate.exercises.map((exerciseId) => {
                      const exercise = allExercises.find((e) => e.id === exerciseId);
                      return (
                        <span key={exerciseId} className="bg-blue-100 text-blue-800 text-sm font-medium px-2.5 py-0.5 rounded-full flex items-center">
                          {exercise ? exercise.exercise_name : 'Unknown'}
                          <button
                            type="button"
                            className="ml-1 text-blue-500 hover:text-blue-700"
                            onClick={() => handleExerciseRemove(exerciseId)}
                          >
                            &times;
                          </button>
                        </span>
                      );
                    })}
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-400 transition-all duration-300 ease-in-out"
                    >
                      Add Workout Template
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default WorkoutTemplates;