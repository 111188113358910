import React from 'react';
import { useNavigate } from 'react-router-dom';

const ResetSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Password Reset Successful</h1>
        <p className="text-gray-700 mb-6 text-center">
          Your password has been reset successfully.
        </p>
        <button
          className="bg-black text-white p-2 rounded hover:bg-gray-800 w-full"
          onClick={() => navigate('/login')}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default ResetSuccess;