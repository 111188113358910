import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="App flex flex-col min-h-screen w-full">
      <header className="bg-white shadow-lg w-full">
        <div className="max-w-6xl mx-auto px-4 w-full">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center space-x-4">
              <Link to="/" className="text-2xl font-bold text-black-600">GymStay</Link>
              <nav className="hidden md:flex space-x-4 ml-auto">
                <Link to="/features" className="text-gray-700 hover:text-gray-900">Features</Link>
                <Link to="/pricing" className="text-gray-700 hover:text-gray-900">Pricing</Link>
              </nav>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <Link to="/login" className="text-gray-700 hover:text-gray-900">Log in</Link>
              <Link to="/register" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400">Register</Link>
            </div>
            <div className="md:hidden flex items-center">
              <button className="mobile-menu-button" onClick={toggleMobileMenu}>
                <span className="text-2xl">&#9776;</span>
              </button>
            </div>
          </div>
        </div>
        <div className={`mobile-menu ${isMobileMenuOpen ? '' : 'hidden'} md:hidden`}>
          <Link to="/features" className="block py-2 px-4 text-sm hover:bg-gray-200">Features</Link>
          <Link to="/pricing" className="block py-2 px-4 text-sm hover:bg-gray-200">Pricing</Link>
          <Link to="/login" className="block py-2 px-4 text-sm hover:bg-gray-200">Log in</Link>
          <Link to="/register" className="block py-2 px-4 text-sm">
            <span className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-400 inline-block w-full text-center">Register</span>
          </Link>
        </div>
      </header>

      <main className="flex-grow flex flex-col items-center justify-center text-center py-20 bg-white px-4 w-full">
        <h1 className="text-3xl md:text-5xl font-bold mb-4">Our Mission</h1>
        <p className="text-lg md:text-2xl mb-8 max-w-3xl">
          To equip gym owners with cutting-edge retention tools, actionable insights, and personalized strategies to foster member loyalty, thereby revolutionizing fitness businesses.
        </p>
        <div className="flex space-x-4">
          <Link to="/get-started" className="bg-gray-200 text-gray-700 px-6 py-3 rounded hover:bg-gray-300">Get Started</Link>
        </div>
      </main>
    </div>
  );
};

export default LandingPage;