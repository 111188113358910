import React from 'react';
import Sidebar from '../Sidebar/sidebar';
// import GymOwnerDashboard from '../Dashboard/GymOwnerDashboard';


const Home = () => {
  return (
    <div className="flex">
      <Sidebar />
      {/* <GymOwnerDashboard /> */}
      <div className="flex-grow p-8">
        <h1 className="text-4xl font-bold mb-4">Welcome to Your Gym Dashboard</h1>
        <p className="text-lg">
          Here you can manage your gym, view attendance, schedule classes, and much more.
        </p>
        {/* Add more content here */} 
      </div>
    </div>
  );
};

export default Home;  