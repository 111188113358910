import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Sidebar from '../Sidebar/sidebar';

const WorkoutExerciseDetails = () => {
  const { id } = useParams();  // Get exercise ID from URL params
  const [exercise, setExercise] = useState(null);  // Store exercise details
  const [progress, setProgress] = useState([]);  // Store progress logs
  const [newProgress, setNewProgress] = useState({
    sets: '',
    reps: '',
    weight: '',
    date: '',
  });
  const [showModal, setShowModal] = useState(false);  // Toggle for add progress modal
  const [isSubmitting, setIsSubmitting] = useState(false);  // For form submission state
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;  // Your API URL

  useEffect(() => {
    fetchExerciseDetails();
    fetchProgress();
  }, [id]);

  // Fetch exercise details
  const fetchExerciseDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}exercises/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setExercise(response.data);
    } catch (err) {
      console.error('Error fetching exercise details:', err);
      setError('Failed to fetch exercise details. Please try again later.');
    }
  };

  // Fetch member progress for the exercise
  const fetchProgress = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}exercises/${id}/progress/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setProgress(response.data);
    } catch (err) {
      console.error('Error fetching progress:', err);
      setError('Failed to fetch progress. Please try again later.');
    }
  };

  // Handle input changes for new progress
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProgress((prevProgress) => ({
      ...prevProgress,
      [name]: value,
    }));
  };

  // Add new progress
  const handleAddProgress = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;  // Prevent multiple submissions
    setIsSubmitting(true);
    setError(null);

    const formData = {
      ...newProgress,
      date: new Date().toISOString().split('T')[0],  // Assuming today’s date by default
    };

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}exercises/${id}/progress/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setShowModal(false);
      fetchProgress();  // Refresh progress list
      setNewProgress({
        sets: '',
        reps: '',
        weight: '',
        date: '',
      });  // Reset form
    } catch (err) {
      console.error('Error adding progress:', err);
      setError('Failed to add progress. Please check your inputs and try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {exercise && (
          <>
            <h2 className="text-2xl font-bold mb-4">Exercise Details</h2>
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
              <div className="flex items-center mb-4">
                <img
                  src={exercise.profile_photo_path || "https://www.tailwindai.dev/placeholder.svg"}
                  alt="Exercise"
                  className="w-24 h-24 rounded mr-4"
                />
                <div>
                  <h3 className="text-xl font-semibold">{exercise.exercise_name}</h3>
                  <p className="text-gray-600">Muscle Group: {exercise.muscle_group}</p>
                  <p className="text-gray-600">Created By: {exercise.created_by_name}</p>
                </div>
              </div>
              <p className="text-gray-700 mt-4">{exercise.description}</p>
            </div>

            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Member Progress List</h3>
              <button
                className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-all duration-300 ease-in-out"
                onClick={() => setShowModal(true)}
              >
                Add Progress
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full bg-white shadow-lg rounded-lg">
                <thead>
                  <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                    <th className="py-3 px-6 text-left">Member Name</th>
                    <th className="py-3 px-6 text-left">Date</th>
                    <th className="py-3 px-6 text-left">Sets</th>
                    <th className="py-3 px-6 text-left">Reps</th>
                    <th className="py-3 px-6 text-left">Weight</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 text-sm font-light">
                  {progress.map((entry) => (
                    <tr key={entry.id} className="border-b border-gray-200 hover:bg-gray-100">
                      <td className="py-3 px-6">{entry.member_name}</td>
                      <td className="py-3 px-6">{entry.date}</td>
                      <td className="py-3 px-6">{entry.sets}</td>
                      <td className="py-3 px-6">{entry.reps}</td>
                      <td className="py-3 px-6">{entry.weight}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Progress</h3>
                <form onSubmit={handleAddProgress}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Sets</label>
                    <input
                      type="number"
                      name="sets"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newProgress.sets}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Reps</label>
                    <input
                      type="number"
                      name="reps"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newProgress.reps}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Weight</label>
                    <input
                      type="text"
                      name="weight"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newProgress.weight}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <div className="mt-6 flex items-center justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Adding...' : 'Add Progress'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default WorkoutExerciseDetails;
