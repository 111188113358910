import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const MemberPassSetup = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false); // New state for loading
  const { unique_string } = useParams();
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  // New: Log API_URL for debugging
  useEffect(() => {
    // console.log('API URL:', API_URL);
  }, [API_URL]);

  const handleSubmit = async (event) => {
    event.preventDefault(); 
    setError(''); 
    setSuccessMessage('');
    setLoading(true); // Start loading

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setLoading(false); // Stop loading on error
      return;
    }

    try {
      const formData = new FormData();
      formData.append('new_password', newPassword);
      formData.append('agree_terms', agreeTerms);
      if (profilePhoto) {
        formData.append('profile_photo', profilePhoto);
      }

      const response = await axios.post(
        `${API_URL}users/invite-email/action/${unique_string}/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        const { gym_name, profile_photo_path, url } = response.data;
        setSuccessMessage('Account successfully set up');
        setTimeout(() => {
          setLoading(false); // Stop loading before navigating
          navigate(url, {
            state: { gymName: gym_name, profilePhotoPath: profile_photo_path },
          });
        }, 1000);
      } else {
        setError('Error setting up account');
        setLoading(false); // Stop loading on error
      }
    } catch (err) {
      console.error('Error setting up account:', err.response?.data || err.message);
      setError(err.response?.data?.error || 'Error setting up account');
      setLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      {loading ? (
        <div className="text-center">Loading...</div> // Loader
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full mx-4">
          <h1 className="text-3xl font-bold mb-6 text-center">Finalize Member Account Creation</h1>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          {successMessage && <p className="text-green-500 text-center mb-4">{successMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="new-password" className="block text-gray-700 mb-2 font-bold">New Password</label>
              <input
                type="password"
                id="new-password"
                className="w-full p-2 border rounded"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                aria-label="New Password"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="confirm-password" className="block text-gray-700 mb-2 font-bold">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                className="w-full p-2 border rounded"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                aria-label="Confirm Password"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="profile-photo" className="block text-gray-700 mb-2 font-bold">Gym Profile Photo</label>
              <input
                type="file"
                id="profile-photo"
                className="w-full p-2 border rounded"
                onChange={(e) => setProfilePhoto(e.target.files[0])}
                aria-label="Profile Photo"
                required
              />
            </div>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="terms"
                className="form-checkbox"
                checked={agreeTerms}
                onChange={(e) => setAgreeTerms(e.target.checked)}
                aria-label="Terms of Service"
                required
              />
              <label htmlFor="terms" className="ml-2 text-gray-700">
                I agree to the <a href="#" className="text-blue-500 hover:underline">Terms of Service</a>
              </label>
            </div>
            <button type="submit" className="w-full bg-black text-white p-2 rounded hover:bg-gray-800">Submit</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default MemberPassSetup;