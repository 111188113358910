import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const Members = () => {
  const [gymDetails, setGymDetails] = useState(null);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 
  const [inviteError, setInviteError] = useState(''); 
  const [newMember, setNewMember] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    dob: null,
    gender: '',
    user_address: '',
    gym_id: '',
  });
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchGymDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}gyms/detail/`, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setGymDetails(response.data[0]);
      } catch (err) {
        console.error('Error fetching gym details:', err);
      }
    };

    fetchGymDetails();
  }, [API_URL]);

  useEffect(() => {
    if (gymDetails) fetchMembers();
  }, [gymDetails]);

  const fetchMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}users/gym-member/${gymDetails.id}/`, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setMembers(response.data);
    } catch (err) {
      console.error('Error fetching gym members:', err);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredMembers = members.filter((member) =>
    member.first_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastMember = currentPage * itemsPerPage;
  const indexOfFirstMember = indexOfLastMember - itemsPerPage;
  const currentMembers = filteredMembers.slice(indexOfFirstMember, indexOfLastMember);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleInputChange = (e) => {
    setNewMember({
      ...newMember,
      [e.target.id]: e.target.value,
      gym_id: gymDetails ? gymDetails.id : '',
    });
  };

  const handleInvite = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader when invite starts
    setInviteError(''); // Clear previous error messages

    try {
      const token = localStorage.getItem('token');

      // Format the date before sending
      const formattedDOB = newMember.dob ? format(newMember.dob, 'yyyy-MM-dd') : '';

      await axios.post(`${API_URL}users/gym-member/invite/`, 
        { ...newMember, dob: formattedDOB }, // Use the formatted DOB
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setShowInviteForm(false); // Hide modal after invite
      await fetchMembers(); // Refresh member list after invite
    } catch (err) {
      console.error('Error inviting new member:', err);

      // Check if the error response contains the custom error message
      if (err.response && err.response.data && err.response.data.message) {
        setInviteError(err.response.data.message); // Set the specific error message
      } else {
        setInviteError('An error occurred while inviting the member.'); // General error message
      }
    } finally {
      setIsLoading(false); // Hide loader once invite completes
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Members</h1>
          <button onClick={() => setShowInviteForm(true)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400">
            Invite
          </button>
        </div>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search for members..."
            className="w-full p-2 border rounded"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        <div className="bg-white p-4 rounded shadow">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-150">
                <th className="py-2 px-4 text-left font-semibold">Member Name</th>
                <th className="py-2 px-4 text-left font-semibold">Email</th>
                <th className="py-2 px-4 text-left font-semibold">Phone Number</th>
                <th className="py-2 px-4 text-left font-semibold">Created At</th>
                <th className="py-2 px-4 text-left font-semibold">Verified</th>
                <th className="py-2 px-4 text-left font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentMembers.map((member) => (
                <tr key={member.id} className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4 font-light">{member.first_name} {member.last_name}</td>
                  <td className="py-2 px-4 font-light">{member.email}</td>
                  <td className="py-2 px-4 font-light">{member.phone_number}</td>
                  <td className="py-2 px-4 font-light">{new Date(member.created_at).toLocaleDateString()}</td>
                  <td className="py-2 px-4 font-light">
                    {member.email_verified ? (
                      <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">Verified</span>
                    ) : (
                      <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded">Not Verified</span>
                    )}
                  </td>
                  <td className="py-2 px-4">
                      <Link to={`/member/${gymDetails?.id}/${member.id}`} className="text-blue-500 hover:underline">View</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {filteredMembers.length > itemsPerPage && (
          <div className="flex justify-center mt-4">
            <nav className="relative z-0 inline-flex shadow-sm">
              {[...Array(Math.ceil(filteredMembers.length / itemsPerPage)).keys()].map(number => (
                <button
                  key={number + 1}
                  onClick={() => paginate(number + 1)}
                  className={`px-3 py-1 border rounded-md ${
                    currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-white text-gray-700'
                  } hover:bg-blue-400 hover:text-white`}
                >
                  {number + 1}
                </button>
              ))}
            </nav>
          </div>
        )}

        {showInviteForm && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="flex justify-between items-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Invite New Member</h3>
                      <button
                        onClick={() => setShowInviteForm(false)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <span className="sr-only">Close</span>
                        &times;
                      </button>
                    </div>
                    <div className="mt-2">
                      <form onSubmit={handleInvite}>
                        <div className="mb-4 text-left">
                          <label htmlFor="first_name" className="block text-gray-700 mb-2 font-semibold">First Name</label>
                          <input
                            type="text"
                            id="first_name"
                            className="w-full p-2 border rounded"
                            placeholder="Enter first name"
                            value={newMember.first_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="last_name" className="block text-gray-700 mb-2 font-semibold">Last Name</label>
                          <input
                            type="text"
                            id="last_name"
                            className="w-full p-2 border rounded"
                            placeholder="Enter last name"
                            value={newMember.last_name}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="email" className="block text-gray-700 mb-2 font-semibold">Email</label>
                          <input
                            type="email"
                            id="email"
                            className="w-full p-2 border rounded"
                            placeholder="Enter email"
                            value={newMember.email}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="phone_number" className="block text-gray-700 mb-2 font-semibold">Phone Number</label>
                          <input
                            type="text"
                            id="phone_number"
                            className="w-full p-2 border rounded"
                            placeholder="Enter phone number"
                            value={newMember.phone_number}
                            onChange={handleInputChange}
                          />
                        </div>
                        
                        {/* Updated Date of Birth Field */}
                        <div className="mb-4 text-left">
                          <label htmlFor="dob" className="block text-gray-700 mb-2 font-semibold">Date of Birth</label>
                          <DatePicker
                            selected={newMember.dob}
                            onChange={(date) => setNewMember({ ...newMember, dob: date })}
                            dateFormat="MM-dd-yyyy"
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholderText="Select date"
                          />
                        </div>

                        <div className="mb-4 text-left">
                          <label htmlFor="gender" className="block text-gray-700 mb-2 font-semibold">Gender</label>
                          <select
                            id="gender"
                            className="w-full p-2 border rounded"
                            value={newMember.gender}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                        <div className="mb-4 text-left">
                          <label htmlFor="user_address" className="block text-gray-700 mb-2 font-semibold">User Address</label>
                          <input
                            type="text"
                            id="user_address"
                            className="w-full p-2 border rounded"
                            placeholder="Enter address"
                            value={newMember.user_address}
                            onChange={handleInputChange}
                          />
                        </div>
                        
                        {inviteError && (
                          <div className="mb-4 text-red-600 text-sm font-semibold">
                            {inviteError}
                          </div>
                        )}

                        {isLoading ? (
                          <div className="flex justify-center py-2">
                            <span>Sending invite...</span>
                          </div>
                        ) : (
                          <div className="flex items-center justify-end space-x-3">
                            <button
                              type="button"
                              onClick={() => setShowInviteForm(false)}
                              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                            >
                              Send Invite
                            </button>
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Members;