import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import auth from '../../api/auth';

const SignUpPage = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 8;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return password.length >= minLength && specialCharRegex.test(password);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^(07|01)\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return `254${phoneNumber.slice(1)}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and contain at least one special character');
      return;
    }

    if (!validatePhoneNumber(phoneNumber)) {
      setError('Phone number must be 10 digits long and start with 07 or 01');
      return;
    }
 
    try {
      const response = await auth.signUp({
        email,
        first_name: firstName,
        last_name: lastName,
        phone_number: formatPhoneNumber(phoneNumber),
        password,
        terms_and_conditions: termsAndConditions,
      });
      console.log(response.data);
      NotificationManager.success('Sign up successful! Please check your email to verify your account.', 'Success');
      navigate('/signup-success');
    } catch (err) {
      console.error(err);
      console.log('Error response:', err.response);

      if (err.response && err.response.status === 400) {
        if (err.response.data.error === 'Email already exists. Please use a different email.') {
          NotificationManager.error('Email already exists. Please use a different email.', 'Error');
        } else {
          NotificationManager.error('An error occurred during registration. Please try again.', 'Error');
        }
      } else {
        NotificationManager.error('An error occurred during registration. Please try again.', 'Error');
      }
    }
  };

  return (
    <div className="App flex flex-col items-center justify-center min-h-screen w-full">
      <div className="bg-white shadow-lg rounded-lg flex max-w-4xl w-full">
        <div className="hidden md:flex md:w-1/2 bg-black text-white p-8 flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold mb-2">Welcome</h1>
          <p className="text-xl">Beysix</p>
          <img src="https://via.placeholder.com/300" alt="placeholder" className="my-4" />
        </div>
        <div className="p-8 md:w-1/2 w-full">
          <h1 className="text-2xl font-bold mb-6 text-center">Sign Up</h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 mb-2">Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-2 border rounded"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="firstName" className="block text-gray-700 mb-2">First Name</label>
              <input
                type="text"
                id="firstName"
                className="w-full p-2 border rounded"
                placeholder="Enter your first name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="block text-gray-700 mb-2">Last Name</label>
              <input
                type="text"
                id="lastName"
                className="w-full p-2 border rounded"
                placeholder="Enter your last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block text-gray-700 mb-2">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                className="w-full p-2 border rounded"
                placeholder="Enter your phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                maxLength={10}
                pattern="[0-9]*"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 mb-2">Password</label>
              <input
                type="password"
                id="password"
                className="w-full p-2 border rounded"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-gray-700 mb-2">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full p-2 border rounded"
                placeholder="Confirm your password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">
                <input
                  type="checkbox"
                  checked={termsAndConditions}
                  onChange={(e) => setTermsAndConditions(e.target.checked)}
                  required
                />
                <span className="ml-2">I agree to the <Link to="/terms" className="text-blue-500 hover:underline">Terms of Service</Link></span>
              </label>
            </div>
            <button type="submit" className="bg-black text-white p-2 rounded hover:bg-gray-800 w-full">Sign Up</button>
            <p className="mt-6 text-center text-gray-700">
              Already have an account? <Link to="/login" className="text-blue-500 hover:underline">Sign in</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;