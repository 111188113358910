import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useParams } from 'react-router-dom';

const ClassDetails = () => {
  const [classData, setClassData] = useState(null);
  const [members, setMembers] = useState([]);
  const [memberCount, setMemberCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [availableMembers, setAvailableMembers] = useState([]); 
  const [selectedMembers, setSelectedMembers] = useState([]); 
  const [errorMessage, setErrorMessage] = useState(""); 
  const [successMessage, setSuccessMessage] = useState("");
  const { id } = useParams(); 
  const API_URL = process.env.REACT_APP_API_URL;

 
  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}classes/${id}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setClassData(response.data);
        setMembers(response.data.members || []);  
        setMemberCount(response.data.members ? response.data.members.length : 0);
      } catch (err) {
        console.error('Error fetching class details:', err);
      }
    };

    fetchClassDetails();
  }, [API_URL, id]);

  // Fetch available gym members not in the class
  const fetchAvailableMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}users/gym-member/${classData.gym}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      // Filter out members already in the class
      const filteredMembers = response.data.filter(
        member => !members.some(classMember => classMember.member === member.user)
      );
      setAvailableMembers(filteredMembers);
    } catch (err) {
      console.error('Error fetching available gym members:', err);
    }
  };

  // Handle modal open to add members
  const handleAddMembers = () => {
    fetchAvailableMembers(); // Fetch available members when modal opens
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setErrorMessage(""); // Clear any previous error messages
    setSuccessMessage(""); // Clear success messages
  };

  // Handle selecting or deselecting a member (GymMemberProfile ID)
  const handleMemberSelect = (memberId) => {
    if (selectedMembers.includes(memberId)) {
      setSelectedMembers(selectedMembers.filter(id => id !== memberId));
    } else {
      setSelectedMembers([...selectedMembers, memberId]);
    }
    console.log("Selected Members (GymMemberProfile IDs):", selectedMembers); // Log selected members
  };

  // Handle adding selected members to class (POST request)
  const handleAddSelectedMembers = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log("Sending GymMemberProfile IDs to backend:", selectedMembers);
      const response = await axios.post(
        `${API_URL}classes/${id}/members/`,
        { members: selectedMembers }, // Sending GymMemberProfile IDs to the backend
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      
      setErrorMessage(''); // Clear previous error
      setSuccessMessage(`${selectedMembers.length} member(s) added to the class successfully.`); // Show success message
      setShowModal(false); // Close modal after successful addition

      // Fetch updated class details
      const updatedClassDetails = await axios.get(`${API_URL}classes/${id}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setClassData(updatedClassDetails.data);
      setMembers(updatedClassDetails.data.members || []);
      setMemberCount(updatedClassDetails.data.members.length);

    } catch (err) {
      if (err.response && err.response.data) {
        if (err.response.data.message === 'Class capacity exceeded') {
          setErrorMessage(
            `Class capacity exceeded. Current members: ${err.response.data.current_member_count}, Capacity: ${err.response.data.class_capacity}.`
          );
        } else if (err.response.data.errors) {
          const errorArray = err.response.data.errors.map(error => `Member ID ${error.member_id}: ${error.errors}`).join(', ');
          setErrorMessage(`Errors: ${errorArray}`);
        } else {
          setErrorMessage('Error adding members.');
        }
      } else {
        setErrorMessage('Error adding members.');
      }
      console.error('Error adding members:', err);
    }
  };

  // Handle deleting a member from the class (DELETE request)
  const handleDeleteMember = async (memberId) => {
    const confirmation = window.confirm('Are you sure you want to remove this member from the class?');
    if (confirmation) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${API_URL}classes/${id}/members/${memberId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        // Update the state after successful deletion
        setMembers(members.filter(member => member.member !== memberId));  
        setMemberCount(memberCount - 1);  
        setErrorMessage(''); // Clear any error messages
      } catch (err) {
        setErrorMessage('Error removing member.');
        console.error('Error removing member:', err);
      }
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        {classData ? (
          <div>
            {/* Class Information Section */}
            <div className="bg-white shadow-lg rounded-lg flex p-4">
              <div className="flex-1 pr-4">
                <h1 className="text-xl font-bold mb-2">{classData.class_name}</h1>
                <p className="text-sm text-gray-700 mb-2">
                  {classData.class_description || 'No description available'}
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  Capacity: {classData.class_capacity} Students
                </p>
                <p className="text-sm text-gray-700">Count: {memberCount} Members</p>
              </div>
              <div className="relative w-32 h-32 flex-shrink-0">
                <img
                  src={
                    classData.profile_photo_path ||
                    'https://www.tailwindai.dev/placeholder.svg'
                  }
                  alt="Class"
                  className="absolute inset-0 w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>

            {/* Error and Success Message Section */}
            {errorMessage && (
              <div className="bg-red-100 text-red-700 p-4 rounded mb-4">
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div className="bg-green-100 text-green-700 p-4 rounded mb-4">
                {successMessage}
              </div>
            )}

            {/* Members List Section */}
            <div className="p-6 w-full">
              <div className="bg-white shadow-lg rounded-lg p-4">
                <h2 className="text-xl font-bold mb-4 flex justify-between items-center">
                  Members In This Class
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                    onClick={handleAddMembers}
                  >
                    Add
                  </button>
                </h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr className="bg-gray-200 text-gray-600">
                        <th className="py-2 px-4 border-b">Member Name</th>
                        <th className="py-2 px-4 border-b">Email</th>
                        <th className="py-2 px-4 border-b">Phone Number</th>
                        <th className="py-2 px-4 border-b">Gender</th>
                        <th className="py-2 px-4 border-b">DOB</th>
                        <th className="py-2 px-4 border-b">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {members.length > 0 ? (
                        members.map((member) => (
                          <tr key={member.member} className="hover:bg-gray-100">
                            <td className="py-2 px-4 border-b">
                              {member.member_name || 'N/A'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {member.email || 'N/A'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {member.phone_number || 'N/A'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {member.gender || 'N/A'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              {member.DOB
                                ? new Date(member.DOB).toLocaleDateString()
                                : 'N/A'}
                            </td>
                            <td className="py-2 px-4 border-b">
                              <button
                                className="text-red-500 hover:text-red-700"
                                onClick={() => handleDeleteMember(member.member)}
                              >
                                <i className="fa-solid fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            className="py-2 px-4 border-b text-center"
                          >
                            No members found for this class.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Modal for Adding Members */}
            {showModal && (
              <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                  <h2 className="text-xl font-bold mb-4">Add Members</h2>
                  <p>
                    Select members to add to this class. Maximum capacity is{' '}
                    {classData.class_capacity} members.
                  </p>
                  <div className="max-h-60 overflow-y-auto mt-4">
                    {availableMembers.length > 0 ? (
                      availableMembers.map((member) => (
                        <div
                          className="flex items-center mb-2"
                          key={member.id}
                        >
                          <input
                            type="checkbox"
                            id={`member${member.id}`}
                            className="mr-2"
                            value={member.id}
                            checked={selectedMembers.includes(member.id)}
                            onChange={() => handleMemberSelect(member.id)}
                          />
                          <label
                            htmlFor={`member${member.id}`}
                            className="text-gray-700"
                          >
                            {member.first_name} {member.last_name}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>No available members to add.</p>
                    )}
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                      onClick={handleCloseModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                      onClick={handleAddSelectedMembers}
                      disabled={selectedMembers.length === 0}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p>Loading class details...</p>
        )}
      </main>
    </div>
  );
};

export default ClassDetails;