import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { debounce } from 'lodash';

const EmailVerified = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState('Verifying...');
  const [showLoginButton, setShowLoginButton] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const verifyEmail = debounce(async (key) => {
    console.log('Verifying email with key:', key);
    try {
      const response = await axios.get(`${API_URL}users/auth/verify-email/${key}/`);
      console.log('Verification response:', response);

      const { status, data } = response;
      if (status === 200) {
        setMessage(data.message || 'Your email has been verified successfully.');
      } else if (status === 208 || data.status === 'already-verified') {
        setMessage(data.message || 'Your email was already verified.');
      } else {
        setMessage(data.message || 'Email verification failed.');
      }
      setShowLoginButton(true);
    } catch (error) {
      console.log('Error during email verification:', error);
      if (error.response) {
        // Handle specific error messages returned from the backend
        setMessage(error.response.data.message || 'An error occurred during email verification.');
      } else {
        setMessage('An error occurred during email verification.');
      }
      setShowLoginButton(true);
    }
  }, 300); // 300ms debounce

  useEffect(() => {
    if (key) {
      verifyEmail(key);
    }
    // Cleanup function to cancel debounce on component unmount
    return () => verifyEmail.cancel();
  }, [key]);

  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Email Verification</h1>
        <p className="text-gray-700 mb-6 text-center">{message}</p>
        {showLoginButton && (
          <button
            className="bg-black text-white p-2 rounded hover:bg-gray-800 w-full"
            onClick={() => {
              console.log('Navigate to login clicked');
              navigate('/login');
            }}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailVerified;