import React from 'react';
import { Link } from 'react-router-dom';

const SignUpSuccessPage = () => {
  return (
    <div className="bg-gray-100 font-sans leading-normal tracking-normal h-screen flex items-center justify-center">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
        <h1 className="text-2xl font-bold mb-6 text-center">Sign Up Successful</h1>
        <p className="text-gray-700 mb-6 text-center">Please check your email to verify your account</p>
        
      </div>
    </div>
  );
};

export default SignUpSuccessPage;