import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/sidebar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const MemberDetail = () => {
  const { gymId, memberId } = useParams();
  const [memberData, setMemberData] = useState(null);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('info');
  const [workoutTab, setWorkoutTab] = useState('schedule'); // New: Sub-tabs for Workouts
  const [isEditing, setIsEditing] = useState(false);  
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [newGoalData, setNewGoalData] = useState({
    goal_name: '',
    goal_type: '',
    goal_description: '',
    deadline_in_months: '',
    goal_status: '',
    current_goal_image_name: null
  });

  const API_URL = process.env.REACT_APP_API_URL;

  // Fake Data for Workout Schedule and Workout Progress
  const workoutSchedule = {
    Monday: ['Chest & Back'],
    Tuesday: ['Leg Day'],
    Wednesday: ['Rest'],
    Thursday: ['Arms & Shoulders'],
    Friday: ['Full Body'],
    Saturday: ['Cardio'],
    Sunday: ['Rest']
  };

  const workoutProgress = [
    {
      member_name: 'Alice Smith',
      date: '2023-10-01',
      sets: 3,
      reps: 10,
      exercise: 'Squat',
      weight: '60kg',
      notes: 'Felt strong'
    },
    {
      member_name: 'Bob Johnson',
      date: '2023-10-02',
      sets: 4,
      reps: 12,
      exercise: 'Bench Press',
      weight: '80kg',
      notes: 'Need to improve form'
    },
    {
      member_name: 'Charlie Brown',
      date: '2023-10-03',
      sets: 5,
      reps: 15,
      exercise: 'Deadlift',
      weight: '100kg',
      notes: 'Great progress!'
    }
  ];

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}users/gym-member/${gymId}/${memberId}/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setMemberData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching member details:', error);
        setLoading(false);
      }
    };

    fetchMemberDetails();
  }, [API_URL, gymId, memberId]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}packages/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        setPackages(response.data);
      } catch (error) {
        console.error('Error fetching packages:', error);
      }
    };

    fetchPackages();
  }, [API_URL]);

  const handlePackageAssignment = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}packages/assign-package/${memberId}/`, 
      { package_id: selectedPackage }, 
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      toast.success('Package successfully assigned!');
      setIsEditing(false);  
      window.location.reload(); 
    } catch (error) {
      console.error('Error assigning package:', error);
      toast.error('Failed to assign package.');
    }
  };

  const handleEditPackage = () => {
    setSelectedPackage(memberData.package?.id || '');
    setIsEditing(true);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoalData({
      ...newGoalData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setNewGoalData({
      ...newGoalData,
      current_goal_image_name: e.target.files[0]
    });
  };

  const handleSaveGoal = async () => {
    const formData = new FormData();
    formData.append('goal_name', newGoalData.goal_name);
    formData.append('goal_type', newGoalData.goal_type);
    formData.append('goal_description', newGoalData.goal_description);
    formData.append('deadline_in_months', newGoalData.deadline_in_months);
    formData.append('goal_status', newGoalData.goal_status);
    if (newGoalData.current_goal_image_name) {
      formData.append('current_goal_image_name', newGoalData.current_goal_image_name);
    }

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}goals/${memberId}/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Goal added successfully!');
      setIsModalOpen(false);
      window.location.reload();
    } catch (error) {
      console.error('Error adding goal:', error);
      toast.error('Failed to add goal.');
    }
  };

  // Workout Schedule: Remove a workout (just UI for now)
  const removeWorkout = (day, workout) => {
    workoutSchedule[day] = workoutSchedule[day].filter(w => w !== workout);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!memberData) {
    return <p>No member data available.</p>;
  }

  const { first_name, last_name, email, phone_number, DOB, user_address, email_verified, package: memberPackage, goals } = memberData;

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <header className="flex items-center mb-6">
          <img
            src={memberData.profile_photo_path || "https://placehold.co/100x100"}
            alt={`${first_name} ${last_name}`}
            className="rounded-full mr-4"
            style={{ width: '100px', height: '100px' }}
          />
          <h1 className="text-2xl font-bold">{`${first_name} ${last_name}`}</h1>
        </header>
        <nav className="mb-6">
          <ul className="flex space-x-4">
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'info' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-blue-400`}
                onClick={() => setActiveTab('info')}
              >
                Member Info
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'package' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('package')}
              >
                Member Package
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'goals' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('goals')}
              >
                Member Goals
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'workouts' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('workouts')}
              >
                Member Workouts
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 ${activeTab === 'attendance' ? 'bg-blue-500 text-white' : 'bg-gray-200'} rounded hover:bg-gray-300`}
                onClick={() => setActiveTab('attendance')}
              >
                Member Attendance
              </button>
            </li>
          </ul>
        </nav>

        <section className="bg-white p-4 rounded-lg shadow">
          {/* Info, Package, Goals remain unchanged */}
          {activeTab === 'info' && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Info</h2>
              <p className="mb-2"><strong>Email:</strong> {email}</p>
              <p className="mb-2"><strong>Phone Number:</strong> {phone_number}</p>
              <p className="mb-2"><strong>Joined On:</strong> {new Date(memberData.created_at).toLocaleDateString()}</p>
              <p className="mb-2"><strong>Status:</strong> {email_verified ? 'Active' : 'Inactive'}</p>
              <p className="mb-2"><strong>DOB:</strong> {DOB}</p>
              <p className="mb-2"><strong>Area:</strong> {user_address}</p>
            </>
          )}

          {activeTab === 'package' && memberPackage && !isEditing && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Package</h2>
              <p className="mb-2"><strong>Package Name:</strong> {memberPackage.package_name}</p>
              <p className="mb-2"><strong>Description:</strong> {memberPackage.package_description}</p>
              <p className="mb-2"><strong>Amount:</strong> {memberPackage.amount}</p>
              <p className="mb-2"><strong>Duration:</strong> {memberPackage.duration} month(s)</p>
              <button
                onClick={handleEditPackage}
                className="text-blue-500 hover:underline"
              >
                Edit
              </button>
            </>
          )}

          {activeTab === 'package' && (isEditing || !memberPackage) && (
            <>
              <h2 className="text-xl font-bold mb-4">Owner {memberPackage ? 'Edit' : 'Assign'} Package</h2>
              <select
                value={selectedPackage}
                onChange={(e) => setSelectedPackage(e.target.value)}
                className="mb-4 p-2 border rounded"
              >
                <option value="">Select a package</option>
                {packages.map(pkg => (
                  <option key={pkg.id} value={pkg.id}>
                    {pkg.package_name} - Ksh {pkg.amount} - {pkg.duration} month(s)
                  </option>
                ))}
              </select>
              <button
                onClick={handlePackageAssignment}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                disabled={!selectedPackage}  
              >
                {memberPackage ? 'Update' : 'Add'}
              </button>
            </>
          )}

          {activeTab === 'goals' && goals && goals.length > 0 && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Goals</h2>
              {goals.map(goal => (
                <section key={goal.id} className="bg-white p-4 rounded-lg shadow flex mb-4">
                  <img src={goal.current_goal_image_path || "https://placehold.co/150x100"} alt="Goal Image" className="rounded-lg mr-4 w-1/3" />
                  <div className="flex-1">
                    <h2 className="text-xl font-bold mb-2">Goal Details</h2>
                    <p className="mb-2"><strong>Goal Name:</strong> {goal.goal_name}</p>
                    <p className="mb-2"><strong>Goal Type:</strong> {goal.goal_type}</p>
                    <p className="mb-2"><strong>Goal Description:</strong> {goal.goal_description}</p>
                    <p className="mb-2"><strong>Goal Deadline:</strong> {goal.deadline_in_months} month(s)</p>
                    <p className="mb-2"><strong>Goal Status:</strong> <span className="text-green-500 font-bold">{goal.goal_status}</span></p>
                  </div>
                </section>
              ))}
            </>
          )}

          {activeTab === 'goals' && goals && goals.length === 0 && (
            <>
              <h2 className="text-xl font-bold mb-4">Member Goals</h2>
              <p>No goals set for this member.</p>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                onClick={handleModalOpen}
              >
                Add Goal
              </button>
            </>
          )}

          {/* New: Member Workouts Tab with sub-tabs */}
          {activeTab === 'workouts' && (
            <>
              <div className="mb-6">
                {/* Sub-tabs under Workouts: Schedule and Progress */}
                <button onClick={() => setWorkoutTab('schedule')} className={`py-2 px-4 ${workoutTab === 'schedule' ? 'bg-pink-500 text-white' : 'bg-gray-200'} rounded-l`}>
                  Workout Schedule
                </button>
                <button onClick={() => setWorkoutTab('progress')} className={`py-2 px-4 ${workoutTab === 'progress' ? 'bg-pink-500 text-white' : 'bg-gray-200'} rounded-r`}>
                  Workout Progress
                </button>
              </div>

              {/* Workout Schedule Content */}
              {workoutTab === 'schedule' && (
                <>
                  <div className="bg-white shadow-lg rounded-lg p-8 max-w-lg w-full">
                    <h1 className="text-2xl font-bold mb-6 text-center">Workout Schedule</h1>

                    {Object.entries(workoutSchedule).map(([day, routines]) => (
                      <div key={day} className="mb-4">
                        <h2 className="text-lg font-semibold">{day}</h2>
                        <div className="flex flex-wrap">
                          {routines.map((routine, idx) => (
                            <div key={idx} className="flex items-center bg-blue-500 text-white rounded-full px-3 py-1 mr-2 mb-2">
                              <span>{routine}</span>
                              <button
                                className="ml-2 text-white hover:text-gray-200"
                                onClick={() => removeWorkout(day, routine)}
                              >
                                <i className="fa-solid fa-times"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    <div className="mb-4">
                      <h2 className="text-lg font-semibold">Thursday</h2>
                      <div className="flex flex-wrap">
                        <button
                          id="add-template-btn"
                          className="mt-2 bg-green-500 text-white p-1 rounded hover:bg-green-400 w-32"
                          onClick={() => setIsModalOpen(true)}
                        >
                          Add Template
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* Workout Progress Content */}
              {workoutTab === 'progress' && (
                <>
                  <h2 className="text-xl font-bold mb-4">Workout Progress</h2>
                  <table className="min-w-full bg-white shadow-lg rounded-lg">
                    <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                        <th className="py-3 px-6 text-left">Member Name</th>
                        <th className="py-3 px-6 text-left">Date</th>
                        <th className="py-3 px-6 text-left">Sets</th>
                        <th className="py-3 px-6 text-left">Reps</th>
                        <th className="py-3 px-6 text-left">Exercise</th>
                        <th className="py-3 px-6 text-left">Weight</th>
                        <th className="py-3 px-6 text-left">Notes</th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                      {workoutProgress.map((progress, index) => (
                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                          <td className="py-3 px-6">{progress.member_name}</td>
                          <td className="py-3 px-6">{progress.date}</td>
                          <td className="py-3 px-6">{progress.sets}</td>
                          <td className="py-3 px-6">{progress.reps}</td>
                          <td className="py-3 px-6">{progress.exercise}</td>
                          <td className="py-3 px-6">{progress.weight}</td>
                          <td className="py-3 px-6">{progress.notes}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}

          {activeTab === 'attendance' && (
            <h2 className="text-xl font-bold mb-4">Member Attendance</h2>
          )}
        </section>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
              <h2 className="text-xl font-bold mb-4">Add New Goal</h2>
              <div className="mb-4">
                <label className="block mb-2">Goal Name</label>
                <input
                  type="text"
                  name="goal_name"
                  value={newGoalData.goal_name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Goal Type</label>
                <input
                  type="text"
                  name="goal_type"
                  value={newGoalData.goal_type}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Goal Description</label>
                <textarea
                  name="goal_description"
                  value={newGoalData.goal_description}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Deadline (in months)</label>
                <input
                  type="number"
                  name="deadline_in_months"
                  value={newGoalData.deadline_in_months}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Goal Status</label>
                <input
                  type="text"
                  name="goal_status"
                  value={newGoalData.goal_status}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Goal Image</label>
                <input
                  type="file"
                  name="current_goal_image_name"
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex justify-end">
                <button
                  className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400 mr-2"
                  onClick={handleModalClose}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                  onClick={handleSaveGoal}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default MemberDetail;