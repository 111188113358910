import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import { useNavigate } from 'react-router-dom';

const Workouts = () => {
  const [workouts, setWorkouts] = useState([]);
  const [newWorkout, setNewWorkout] = useState({
    name: '',
    description: '',
    muscle_group: '',
    profile_photo_name: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    fetchWorkouts();
  }, []);

  const fetchWorkouts = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}workouts/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      setWorkouts(response.data);
    } catch (err) {
      console.error('Error fetching workouts:', err);
      setError('Failed to fetch workouts. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewWorkout((prevWorkout) => ({
      ...prevWorkout,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setNewWorkout((prevWorkout) => ({
      ...prevWorkout,
      profile_photo_name: e.target.files[0],
    }));
  };

  const handleAddWorkout = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    setError(null);

    const formData = new FormData();
    formData.append('name', newWorkout.name);
    formData.append('description', newWorkout.description);
    formData.append('muscle_group', newWorkout.muscle_group);
    
    if (newWorkout.profile_photo_name) {
      formData.append('profile_photo_name', newWorkout.profile_photo_name);
    }

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}workouts/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowModal(false);
      fetchWorkouts(); 
      setNewWorkout({
        name: '',
        description: '',
        muscle_group: '',
        profile_photo_name: null,
      });
    } catch (err) {
      console.error('Error adding new workout:', err);
      if (err.response) {
        setError(`Failed to add workout: ${err.response.data.message || 'Please check your inputs and try again.'}`);
      } else {
        setError('Failed to add workout. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleWorkoutClick = (id) => {
    navigate(`/workouts/${id}`);
  };

  return (
    <div className="flex">
      <Sidebar />
      <main className="flex-1 p-6">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold">Workouts</h1>
          <button
            onClick={() => setShowModal(true)}
            className="bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-all duration-300 ease-in-out"
          >
            Add Workout
          </button>
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-lg rounded-lg">
            <thead>
              <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left">Workout Image</th>
                <th className="py-3 px-6 text-left">Workout Name</th>
                <th className="py-3 px-6 text-left">Muscle Group</th>
                <th className="py-3 px-6 text-left">Created By</th>
                <th className="py-3 px-6 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light">
              {workouts.map((workout) => (
                <tr key={workout.id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6">
                    <img
                      src={workout.profile_photo_path || 'https://www.tailwindai.dev/placeholder.svg'}
                      alt={workout.name}
                      className="w-16 h-16 rounded"
                    />
                  </td>
                  <td className="py-3 px-6">{workout.name}</td>
                  <td className="py-3 px-6">{workout.muscle_group}</td>
                  <td className="py-3 px-6">{workout.created_by_name || 'Unknown'}</td>
                  <td className="py-3 px-6">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400"
                      onClick={() => handleWorkoutClick(workout.id)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showModal && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <div className="mt-3">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Add New Workout</h3>
                <form onSubmit={handleAddWorkout}>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Workout Name</label>
                    <input
                      type="text"
                      name="name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newWorkout.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Workout Description</label>
                    <textarea
                      name="description"
                      rows="3"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newWorkout.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Muscle Group</label>
                    <input
                      type="text"
                      name="muscle_group"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      value={newWorkout.muscle_group}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Profile Photo</label>
                    <input
                      type="file"
                      name="profile_photo_name"
                      className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                      onChange={handleFileChange}
                    />
                  </div>
                  {error && <p className="text-red-500 mb-4">{error}</p>}
                  <div className="mt-6 flex items-center justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowModal(false)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isSubmitting ? 'Adding...' : 'Add Workout'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Workouts;